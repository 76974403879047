body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    background-color: #f8f9fa;
    color: #212529;
}

a {
    color: #0d6efd;
    text-decoration: none;
}

a:hover {
    color: #0a58ca;
    text-decoration: underline;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 16px;
}